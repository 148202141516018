import React, { useState, useEffect } from 'react';
import '../styles/nft-lookup.css'; // Ensure the path is correct

// Interfaces
interface Item {
  name: string;
  image_link: string;
  metadata_link: string;
}

interface Data {
  items: {
    [key: string]: Item;
  };
}

interface Attribute {
    trait_type: string;
    value: string;
  }
  
interface File {
  uri: string;
  type: string;
}
  
interface Properties {
  files: File[];
  category: string;
}
  
interface Metadata {
  name: string;
  symbol: string;
  description: string;
  seller_fee_basis_points: number;
  image: string;
  external_url: string;
  attributes: Attribute[];
  properties: Properties;
}

const NFTLookup = () => {
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState('');
  const [item, setItem] = useState<Item | null>(null);
  const [spritesheetURL, setSpritesheetURL] = useState('');
  const [personality, setPersonality] = useState<string | null>(null);
  const [error, setError] = useState(false);
  const [data, setData] = useState<Data>({ items: {} });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await fetch('/cache.json'); // Adjust path if necessary
      const json = await response.json() as Data;
      setData(json);
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleSearch = async () => {
    setLoading(true);
    const foundItem = data.items[input];
    if (foundItem) {
      setItem(foundItem);
      setError(false);
      // Fetch additional metadata
      try {
        const response = await fetch(foundItem.metadata_link);
        const metadata: Metadata = await response.json(); // Now TypeScript knows the structure of metadata
        const personalityTrait = metadata.attributes.find((attr: Attribute) => attr.trait_type === "Personality");
        setSpritesheetURL(metadata.properties.files[1].uri);
        setPersonality(personalityTrait ? personalityTrait.value : "No personality info found");
      } catch (error) {
        console.error('Error fetching personality:', error);
        setPersonality("Error fetching personality info");
      }
    } else {
      setItem(null);
      setPersonality(null);
      setError(true);
    }
    setLoading(false);
  };

  return (
    <div className="nft-lookup">
      <input
        type="number"
        value={input}
        onChange={(event) => {
          const value = event.target.value;
          // Allow the input to be empty, and check for a number greater than or equal to 0
          if (value === '' || parseInt(value, 10) >= 0) {
            setInput(value);
          }
        }}
        placeholder="Enter item number"
      />
      <button onClick={handleSearch}>Search</button>
      {loading ? (
        <p className="nft-loading">Loading...</p>
      ) : item ? (
        <div className="nft-item">
          <h3 className="nft-name">{item.name}</h3>
          <img src={item.image_link} alt={item.name} className="nft-image" />
          {personality && <p className="nft-personality">{personality}</p>}
          <div
            className="sprite-animation"
            style={{ backgroundImage: `url(${spritesheetURL})` }}
          ></div>
        </div>
      ) : error && (
        <p className="nft-error">Only avatar's in the current or previous tiers can be shown.</p>
      )}
    </div>
  );
};

export default NFTLookup;
