import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router
import '../styles/global.css';
import '../styles/navbar.css';
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
        <Link to="/" className="party"style={{ fontSize: '20px' }}>Life of the Party</Link>
          <div className="nav-links">
            <br />
            
            {/* Button triggering minting process */}
            <CrossmintPayButton
              collectionId="8ef52d30-d1ec-413d-9ac8-bfadf7f85004"
              projectId="c80130e7-89f8-4fca-9936-d3fcea19b8af"
              environment="production"
              className="xmint-btn"
              getButtonText={(connecting) =>
                connecting ? "Connecting" : "M" + ("int").toLocaleLowerCase()
              }
              style={{
                border: "none", // Remove border
                color: "black", // Set text color
                padding: "5px 10px",
              }}
              
            />
            <Link to="/about">About</Link>
            <Link to="/lookup">Lookup</Link>
            {/* Add additional links if needed */}
          </div>
        </div>

        <div className="nav-right">
          <Link to="#" className="app-button">APP Coming 2025!</Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
