import React from 'react';
import '../styles/global.css'; // Import global styles

const Background: React.FC = () => {
  return (
    <div className="background">
      <video autoPlay loop muted playsInline poster="/logo512.png" preload="auto">
        <source src="/background.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

    </div>
  );
}

export default Background;
