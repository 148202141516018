import React from 'react';
import '../styles/footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="container">
        <br />
        <div>
          <a href="https://x.com/party______life" target="_blank" rel="noopener noreferrer">Twitter</a>
          <a href="https://discord.gg/mAW8ZUrW" target="_blank" rel="noopener noreferrer">Discord</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
