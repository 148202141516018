import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const AboutPage = () => {
  return (
    <div>
        <Navbar />
        <div style={{ marginTop: '180px', marginRight: '10%', marginLeft: '10%' }}>
            <h2 style={{ color: '#FF69B4', fontSize: '16px', }}>About</h2>
            <br/>
            <p style={{ fontSize: '14px', lineHeight: '1.6' }}>
                Life of the Party is a Solana-based Ai nft collection where avatars get down on the good foot and do the bad thing. 
                Once the APP drops (coming 2025) players will be able to create their own outfits and activities for their avatar's room.
                Hit me up on Discord with any questions.
            </p>
            <br/>
            <br/>
            <h2 style={{ color: '#FF69B4', fontSize: '16px'}}>Minting</h2>
            <br/>
            <div style={{ fontSize: '14px' }}>
                <p style={{ lineHeight: '1.6' }}>
                    Minting is live and priced at different tiers as NFTs are sold (current tier in pink). </p>
                    <br/>
                <p style={{ lineHeight: '1.6' }}>
                    You can use Crossmint to pay with fiat, or DM me on twitter for multiples/ to avoid the Crossmint fee.</p>
                <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                <br/>
                    <li><span style={{ color: '#FF69B4' }}>0 ~ 999: 0.002 SOL</span></li>
                    <li>~ 1999: 0.006 SOL</li>
                    <li>~ 2999: 0.02 SOL</li>
                    <li>~ 3999: 0.06 SOL</li>
                    <li>~ 4999: 0.2 SOL</li>
                    <li>~ 5999: 0.6 SOL</li>
                    <li>~ 6999: 2 SOL</li>
                    <li>~ 7999: 6 SOL</li>
                    <li>~ 8999: 20 SOL</li>
                    <li>~ 9999: 60 SOL</li>
                </ul>
            </div>
            <br/>
            {/*<h2 style={{ color: '#FF69B4', fontSize: '16px', lineHeight: '1.6' }}>Gameplay</h2>
            <br/>
            <p style={{ fontSize: '14px', lineHeight: '1.6' }}>
                Once in game, avatars interact based on their on-chain personalities.
                They choose what to say and what they want to do! They can interact with room activities, dance, listen to music or stand akwardly in the corner....
            </p>
            <br/>
            <br/>
            <h2 style={{ color: '#FF69B4', fontSize: '16px'}}>Tokenomics</h2>
            <br/>
            <p style={{ fontSize: '14px' }}>
                <p>Life of the Party uses an in-game utility token: KARMA</p>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px', lineHeight: '1.6' }}>
                    <br/>
                        <li>- The total supply of KARMA is limited to 10,000,000</li>
                        <br/>
                        <li>- KARMA will have an initial cost = USDC .10</li>
                        <br/>
                        <li>- Players can only purchase 1,000 KARMA per NFT from the Solana program</li>
                        <br/>
                        <li>- If players want to purchase additional KARMA, they'll have to get it from other players or external exchanges</li>
                        <br/>
                        <li>- To host or join a party, players have to stake at least 10 KARMA (no limit)</li>
                        <br/>
                        <li>- Each game, an additional 10% of the staked KARMA is minted into the game's pool. Ai Avatars attribute this KARMA to whichever other Avatar they choose.</li>
                        <br/>
                        <li>- At the end of each game, players receive the amount of KARMA their avatar earned. </li>
                        <br/>
                        <li>- Players can use KARMA to purchase outfits for their avatars and activities/ decorations for their room.</li>
                        <br/>
                        <li>- Players can create their own outfits, activities and decorations and sell them to other players for KARMA</li>
                    </ul>
            </p>
            <br/>
            <h2 style={{ color: '#FF69B4', fontSize: '16px', lineHeight: '1.6' }}>About Me</h2>
            <br/>
            <p style={{ fontSize: '14px', lineHeight: '1.6', marginBottom: '80px' }}>
                I am a blockchain programmer by day, and, well, also a blockchain programmer by night.
                I'm interested the perspective that virtual realities can offer our physical one.
                Hit me up on <a href="https://twitter.com/nile_the_tiger" target="_blank" rel="noopener noreferrer">twitter</a>.
            </p>
            <br/>*/}
        </div>
        <Footer />
    </div>
  );
}

export default AboutPage;
