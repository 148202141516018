// Home.tsx
import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Background from '../components/Background';

const Home = () => {
  return (
    <div>
      <Navbar />
      <Background />
      <Footer />
    </div>
  );
};

export default Home;
